export default [
  {
    path: '',
    name: 'login',
    component: () => import('@/views/cms/login/Login-v1.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/cms/login',
    name: 'auth-login',
    component: () => import('@/views/cms/login/Login-v1.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/logout',
    name: 'logout',
    component: () => import('@/views/cms/login/Logout.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/cms/profile',
    name: 'profile',
    component: () => import('@/views/cms/profile/Edit.vue'),
    meta: {
      resource: 'Profile',
      action: 'edit',
    },
  },
  {
    path: '/cms/kanban',
    name: 'cms-kanban',
    component: () => import('@/views/cms/kanban/Kanban.vue'),
    meta: {
      resource: 'Kanban',
      action: 'read',
    },
  },
  {
    path: '/cms/dashboard',
    name: 'cms-dashboard',
    component: () => import('@/views/cms/dashboard/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
    },
  },
  {
    path: '/cms/conversations',
    name: 'cms-conversations',
    component: () => import('@/views/cms/conversation/List.vue'),
    meta: {
      resource: 'Conversations',
      action: 'read',
    },
  },
  {
    path: '/cms/reports',
    name: 'cms-reports',
    component: () => import('@/views/cms/report/List.vue'),
    meta: {
      resource: 'Reports',
      action: 'read',
    },
  },
  {
    path: '/cms/users',
    name: 'cms-user',
    component: () => import('@/views/cms/user/List.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/cms/users-v2',
    name: 'cms-user-v2',
    component: () => import('@/views/cms/user/ListCommercia.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/cms/smart-reports',
    name: 'cms-tools-smart-reports',
    component: () => import('@/views/cms/tools/smart-reports/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'smart-reports',
    },
  },
  {
    path: '/cms/cms-tools-goal-management',
    name: 'cms-tools-goal-management',
    component: () => import('@/views/cms/tools/goal-management/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-tools-smarties',
    name: 'cms-tools-smarties',
    component: () => import('@/views/cms/tools/smarties/Search.vue'),
    meta: {
      resource: 'Tools',
      action: 'smarties',
    },
  },
  {
    path: '/cms/cms-test-tools',
    name: 'cms-test-tools',
    component: () => import('@/views/cms/tools/tests/Index.vue'),
    meta: {
      resource: 'TestTools',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-reports-ratings',
    name: 'cms-reports-ratings',
    component: () => import('@/views/cms/tools/ratings/List.vue'),
    meta: {
      resource: 'Reports',
      action: 'ratings',
    },
  },
  {
    path: '/cms/cms-tools-turo',
    name: 'cms-tools-turo',
    component: () => import('@/views/cms/tools/turo/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'turo',
    },
  },
  {
    path: '/cms/tools-email-template',
    name: 'tools-email-template',
    component: () => import('@/views/cms/tools/email-template/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/tools-email-template-form',
    name: 'tools-email-template-form-insert',
    component: () => import('@/views/cms/tools/email-template/Form.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/tools-email-template-form/:id',
    name: 'tools-email-template-form-update',
    component: () => import('@/views/cms/tools/email-template/Form.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-tools-migrate-messages',
    name: 'cms-tools-migrate-messages',
    component: () => import('@/views/cms/tools/MigrateMessages.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-tools-import-data',
    name: 'cms-tools-import-data',
    component: () => import('@/views/cms/tools/ImportData.vue'),
    meta: {
      resource: 'Tools',
      action: 'import-data',
    },
  },
  {
    path: '/cms/cms-tools-migrate-user',
    name: 'cms-tools-migrate-user',
    component: () => import('@/views/cms/tools/MigrateUser.vue'),
    meta: {
      resource: 'Tools',
      action: 'migrate-user',
    },
  },
  {
    path: '/cms/user-double-registration/:id',
    name: 'cms-user-double-registration',
    component: () => import('@/views/cms/user/ListDoubleRegistration.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/cms/user-form',
    name: 'cms-user-form-insert',
    component: () => import('@/views/cms/BlankPage.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/cms/user-form/:id',
    name: 'cms-user-form-update',
    component: () => import('@/views/cms/user/FormEdit.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/cms/acesso-direto',
    name: 'acesso-direto',
    component: () => import('@/views/cms/login/AcessoDireto.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/informative',
    name: 'cms-informative',
    component: () => import('@/views/cms/informative/List.vue'),
    meta: {
      resource: 'Auth',
      // layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/informative-form',
    name: 'cms-informative-form-insert',
    component: () => import('@/views/cms/informative/Form.vue'),
    meta: {
      resource: 'Auth',
      // layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/informative-form/:id',
    name: 'cms-informative-form-update',
    component: () => import('@/views/cms/informative/Form.vue'),
    meta: {
      resource: 'Auth',
      // layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/informative-html/:id',
    name: 'cms-informative-html',
    component: () => import('@/views/cms/informative/Html.vue'),
    meta: {
      resource: 'Auth',
      // layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/planner/contents',
    name: 'cms-planner-contents-list',
    component: () => import('@/views/cms/planner/contents/List.vue'),
    props: true,
    meta: {
      resource: 'Planner',
    },
  },
  {
    path: '/cms/planner/contents/edit',
    name: 'cms-planner-contents-edit',
    component: () => import('@/views/cms/planner/contents/Edit.vue'),
    props: true,
    meta: {
      resource: 'Planner',
    },
  },
  {
    path: '/cms/financial',
    name: 'cms-financial',
    component: () => import('@/views/cms/financial/List.vue'),
    props: true,
    meta: {
      resource: 'Financial',
      // layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/cms/recurrence-clients',
    name: 'cms-recurrence-clients',
    component: () => import('@/views/cms/recurrence-clients/List.vue'),
    props: true,
    meta: {
      resource: 'Reports',
      action: 'recurrences',
    },
  },
  {
    path: '/cms/total-clients',
    name: 'cms-total-clients',
    component: () => import('@/views/cms/total-clients/List.vue'),
    props: true,
    meta: {
      resource: 'Reports',
      action: 'total-clients',
    },
  },
  {
    path: '/cms/health-score',
    name: 'cms-health-score',
    component: () => import('@/views/cms/health-score/HealthScore.vue'),
    props: true,
    meta: {
      resource: 'HealthScore',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-tools-active-users',
    name: 'cms-tools-active-users',
    component: () => import('@/views/cms/tools/active-users/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'active-users',
    },
  },
  {
    path: '/cms/cms-tools-chat-follow',
    name: 'cms-tools-chat-follow',
    component: () => import('@/views/cms/tools/chat-follow/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'chat-follow',
    },
  },
  {
    path: '/cms/cms-tools-email-sent',
    name: 'cms-tools-email-sent',
    component: () => import('@/views/cms/tools/email-sent/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/cms-activation-review',
    name: 'cms-tools-activation-review',
    component: () => import('@/views/cms/tools/activation-review/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'activation-review',
    },
  },
  {
    path: '/cms/cms-activation-review-v2',
    name: 'cms-tools-activation-review-v2',
    component: () => import('@/views/cms/tools/activation-review/ListV2.vue'),
    meta: {
      resource: 'Tools',
      action: 'activation-review',
    },
  },
  {
    path: '/cms/cms-tools-disabled-emails',
    name: 'cms-tools-disabled-emails',
    component: () => import('@/views/cms/tools/disabled-emails/List.vue'),
    meta: {
      resource: 'Tools',
      action: 'read',
    },
  },
  {
    path: '/cms/issues',
    name: 'cms-issues',
    component: () => import('@/views/cms/issues/List.vue'),
    props: true,
    meta: {
      resource: 'Issues',
      action: 'read',
    },
  },
  {
    path: '/cms/mass-emails',
    name: 'cms-mass-emails',
    component: () => import('@/views/cms/tools/mass-emails/List.vue'),
    props: true,
    meta: {
      resource: 'Tools',
      action: 'mass-emails',
    },
  },
  {
    path: '/cms/mass-emails/edit',
    name: 'cms-mass-emails-edit',
    component: () => import('@/views/cms/tools/mass-emails/Edit.vue'),
    props: true,
    meta: {
      resource: 'Tools',
      action: 'mass-emails',
    },
  },
  {
    path: '/cms/performance-analysis/',
    name: 'cms-performance-analysis',
    component: () => import('@/views/cms/tools/performance-analysis/Measure.vue'),
    props: true,
    meta: {
      resource: 'Tools',
      action: 'performance-analysis',
    },
  },
  {
    path: '/agendador/user-list/',
    name: 'agendador-user-list',
    component: () => import('@/views/agendador/UserList.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'agendador',
    },
  },
  {
    path: '/agendador/user-edit/',
    name: 'agendador-user-edit',
    component: () => import('@/views/agendador/UserEdit.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'agendador',
    },
  },
  {
    path: '/agendador/datas/',
    name: 'agendador-datas',
    component: () => import('@/views/agendador/DisabledDates.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'agendador',
    },
  },
  {
    path: '/crm/account-list/',
    name: 'crm-account-list',
    component: () => import('@/views/crm/account/AccountList.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'crm',
    },
  },
  {
    path: '/crm/account-edit/:id',
    name: 'crm-account-edit',
    component: () => import('@/views/crm/account/AccountEdit.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'crm',
    },
  },
  {
    path: '/crm/user-list/',
    name: 'crm-user-list',
    component: () => import('@/views/crm/user/UserList.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'crm',
    },
  },
  {
    path: '/crm/user-edit/:userId',
    name: 'crm-user-edit',
    component: () => import('@/views/crm/user/UserEdit.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'crm',
    },
  },
  {
    path: '/crm/product-list/',
    name: 'crm-product-list',
    component: () => import('@/views/crm/product/ProductList.vue'),
    props: true,
    meta: {
      resource: 'Projects',
      action: 'crm',
    },
  },
]
