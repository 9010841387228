/* eslint-disable class-methods-use-this */
import useJwt from '@/auth/jwt/useJwt'

export default class KanbanService {
  listByRole(role = '', accountManagerId = '', column = '', page = 1) {
    return useJwt.get(`/kanban/${role}/${accountManagerId}`, {
      column,
      page,
    })
  }

  getKanbanCard(userId) {
    return useJwt.get(`/kanban/card/${userId}`)
  }

  sortSuccessManager(userId) {
    return useJwt.post(`/kanban/sort-success-manager/${userId}`)
  }

  moveCard(userId, data) {
    return useJwt.post(`/kanban/move-to/${userId}`, {}, data)
  }

  addSchedule(userId, scheduleAt, description) {
    return useJwt.post('/kanban/add-schedule', {}, { user_id: userId, schedule_at: scheduleAt, description })
  }

  moveToFollowUp(userId) {
    return useJwt.post('/kanban/move-to-follow-up', {}, { user_id: userId })
  }

  moveToFinished(userId) {
    return useJwt.post('/kanban/move-to-finished', {}, { user_id: userId })
  }

  salesSdrCancelRegistration(userId) {
    return useJwt.post(`/kanban/sales_sdr/cancel-registration/${userId}`, {}, {})
  }

  salesSdrRegisterContact(column, data) {
    return useJwt.post(`/kanban/sales_sdr/${column}/register-contact`, {}, data)
  }

  doNotWantOnboarding(userId) {
    return useJwt.post('/kanban/do-not-want-onboarding', {}, { user_id: userId })
  }

  addManualSchedule(userId, description) {
    return useJwt.post('/kanban/add-manual-schedule', {}, { user_id: userId, description })
  }

  moveToOnboarding(userId) {
    return useJwt.post('/kanban/move-to-onboarding', {}, { user_id: userId })
  }

  hideUntil(userId, hideUntil, description) {
    return useJwt.post('/kanban/hide-until', {}, { user_id: userId, hide_until: hideUntil, description })
  }

  listActivities(userId) {
    return useJwt.get(`/kanban/activities/${userId}`)
  }

  addUsersInSanitation(data) {
    return useJwt.post('/kanban/additional/add-sanitation-users', {}, data)
  }

  addUsersInCercAssistance(data) {
    return useJwt.post('/kanban/additional/add-cerc-assistance-users', {}, data)
  }

  addUsersInIssueMentoring(data) {
    return useJwt.post('/kanban/additional/add-issue-success-users', {}, data)
  }

  postponeAdditional(id, data) {
    return useJwt.post(`/kanban/additional/postpone/${id}`, {}, data)
  }

  contactedAdditional(id, data) {
    return useJwt.post(`/kanban/additional/contacted/${id}`, {}, data)
  }

  contactedAdditionalMentoring(id, data) {
    return useJwt.post(`/kanban/additional-mentoring/contacted/${id}`, {}, data)
  }

  contactedAdditionalCercAssistance(id, data) {
    return useJwt.post(`/kanban/additional-cerc-assistance/contacted/${id}`, {}, data)
  }

  postponeAdditionalMentoring(id, data) {
    return useJwt.post(`/kanban/additional-mentoring/postpone/${id}`, {}, data)
  }

  postponeAdditionalCercAssistance(id, data) {
    return useJwt.post(`/kanban/additional-cerc-assistance/postpone/${id}`, {}, data)
  }

  listAdditional(columns, accountManagerId = '', page = 1) {
    const args = {
      columns,
      accountManagerId,
      page,
    }
    return useJwt.get('/kanban/additional/by-columns', args)
  }

  postSidebar(role, column, data) {
    return useJwt.post(`/kanban/${role}/${column.replace('_', '-')}/post-sidebar`, {}, data)
  }

  postRegisterContact(userId, role, column, contactMade, annotation, channel, assignTo, newDate, salesContact, bias, salesLeadQualification, description, salesPipeline) {
    // alteração feita
    return useJwt.post(`/kanban/${role}/${column.replace('_', '-')}/register-contact`, {}, {
      user_id: userId,
      contact_made: contactMade,
      annotation,
      channel,
      assign_to: assignTo,
      new_date: newDate,
      sales_contact: salesContact,
      bias,
      sales_lead_qualification: salesLeadQualification,
      description,
      sales_pipeline: salesPipeline,
    })
  }

  postScheduleNoContact(userId) {
    return useJwt.post('/kanban/schedule-no-contact', {}, {
      user_id: userId,
    })
  }
}
